import { parseGwei } from 'viem';

// CANDY
export const CREATE_TOKEN_URL = '/create';

// DEX
export const VAPORDEX_DOCS_URL = 'https://docs.vapordex.io';
export const POOLS_DOCS_URL = `${VAPORDEX_DOCS_URL}/vapordex/liquidity-pools`;
export const STRATOSPHERE_MEMBER_MANAGE_WALLETS_URL = '/stratosphere/profile';

export const V2_POOLS_DOCS_URL = `${POOLS_DOCS_URL}/v2-pools`;
export const LAUNCHING_A_TOKEN_URL = `${POOLS_DOCS_URL}/launching-a-token`;
export const VAPE_V3_ANNOUNCEMENT = 'https://tinyurl.com/24vz4z34';
export const STRATOSPHERE_URL = '/stratosphere';
export const COINPAGES_URL = 'https://coinpages.xyz/';
export const POOL_FEE_PRECISION = 1e4;
export const SABLIER_URL = 'https://blog.sablier.com/introducing-sablier-v2/';
export const DISCORD_URL = 'https://discord.com/invite/mVEGkWQ4NQ';
export const SOCIAL_TWITTER_URL = 'https://twitter.com/_Vaporfi';
export const SOCIAL_VAPORDEX_URL = 'https://app.vapordex.io';
export enum GAS_PRICE {
  default = '25',
  fast = '26',
  instant = '27',
}

export const GAS_PRICE_GWEI = {
  default: parseGwei(GAS_PRICE.default).toString(),
  fast: parseGwei(GAS_PRICE.fast).toString(),
  instant: parseGwei(GAS_PRICE.instant).toString(),
  testnet: parseGwei(GAS_PRICE.default).toString(),
};

export const INITIAL_ALLOWED_SLIPPAGE = 30;
export const SLIPPAGE_TOLERANCE_LOW = 10;
export const SLIPPAGE_TOLERANCE_MEDIUM = 30;
export const SLIPPAGE_TOLERANCE_HIGH = 100;

export function escapeRegExp(string: string): string {
  return string.replaceAll(/[$()*+.?[\\\]^{|}]/g, '\\$&'); // $& means the whole matched string
}

export const validChains = ['Avalanche', 'Telos'];
export const validTiers = [
  'Basic',
  'Silver',
  'Gold',
  'Diamond',
  'Platinum',
  'Obsidian',
];

export const ZERO = BigInt(0);
export const TEN = BigInt(10);
export const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';
