import React from 'react';
import { Container } from '@vaporfi/uikit';
import OneTimeEvents from './components/OneTimeEvents';
import { StratosphereDashboardProps } from './types';
import KPIs from './components/KPIs';
import Progress from './components/Progress';
import ClaimPoints from './components/ClaimPoints';
import { Profile } from '../Profile';
import { getStratosphereAvatarUrl } from '@vaporfi/utils';

export const StratosphereDashboard: React.FC<StratosphereDashboardProps> = ({
  account,
  availablePoints,
  claimablePoints,
  currentTier,
  expiringPoints,
  onClaimPointsClick,
  oneTimeEventStatus,
  pendingPoints,
  progressPercentage,
  tokenId,
  totalPoints,
  weeklyPoints,
}) => {
  return (
    <Container stack="column" className="gap-[30px] p-4 lg:p-[30px]" fullWidth>
      <Profile
        isReadOnly
        isAvatarManagementEnabled
        tokenId={tokenId}
        validAvatar={getStratosphereAvatarUrl(tokenId, account)}
      />
      <Progress
        {...{
          currentTier,
          pendingPoints,
          progressPercentage,
        }}
      />
      <ClaimPoints
        claimablePoints={claimablePoints}
        onClaimPointsClick={onClaimPointsClick}
      />
      <KPIs
        {...{ availablePoints, expiringPoints, totalPoints, weeklyPoints }}
      />
      <OneTimeEvents eventStatus={oneTimeEventStatus} />
    </Container>
  );
};

export * from './constants';
export * from './types';
