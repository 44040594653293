'use client';
import { Thread, MoarCandyToken } from '@vaporfi/db';
import { useIsIntersecting } from '@vaporfi/hooks';
import { Box, Container, LoadingSpinner, Typography } from '@vaporfi/uikit';
import { PaginationProps } from '@vaporfi/utils';
import React, {
  ReactNode,
  Suspense,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import Image from 'next/image';
import { Header, Wrapper } from './shared';
import dynamic from 'next/dynamic';
import Skeleton from './Skeleton';

const Message = dynamic(() => import('./Message'), {
  loading: () => <Skeleton />,
});

const Threads = ({
  children,
  fetchNextPage,
  hasMore,
  threads,
  token,
}: PaginationProps & {
  threads: Thread[];
  hasMore: boolean;
  fetchNextPage: () => void;
  token: MoarCandyToken;
  children: ReactNode;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const isVisible = useIsIntersecting(ref);

  const scrollToBottom = useCallback(() => {
    if (ref && ref?.current) {
      ref?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (isVisible) {
      fetchNextPage();
    }
  }, [fetchNextPage, isVisible]);

  return (
    <Container stack="column" className="gap-4">
      {hasMore && (
        <Typography
          color="dark"
          onClick={scrollToBottom}
          className="cursor-pointer"
        >
          [Scroll to bottom]
        </Typography>
      )}
      {token && (
        <Suspense fallback={<Skeleton />}>
          <Wrapper>
            <Header
              isDev
              author={token?.deployer}
              creationTime={token?.date_created}
            />
            <Box className="flex items-center gap-4">
              <Image
                src={token?.logo}
                height={177}
                width={177}
                alt="logo"
                className="border-purple-4 rounded border-2"
              />
              <Typography color="dark">{token?.description}</Typography>
            </Box>
          </Wrapper>
        </Suspense>
      )}

      {threads?.map((message) => (
        <Message
          message={message}
          deployer={token?.deployer}
          key={`${message?.id}-${message?.author}`}
        />
      ))}
      {hasMore && (
        <Container ref={ref} className="flex w-full items-center">
          <LoadingSpinner />
        </Container>
      )}
      {children}
    </Container>
  );
};

export default Threads;
export * from './PostComment';
