export const ADD_POOL_URL = '/pools';

export const BRIDGE_URL = '/bridge';

export const FIAT_URL = '/fiat';

export const FIND_POOL_URL = '/find';
export const ONRAMPER_URL = '/onramper';
export const GUIDE_CROSS_CHAIN_URL =
  'https://docs.binance.org/smart-chain/guides/cross-chain.html';

export const GENESIS_URL = '/genesis';
export const POOL_URL = '/pools';
export const V2_POOL_URL = `${POOL_URL}/v2`;
export const LIQUID_MINING_URL = '/earn/vape';
export const V1_POOL_URL = `${POOL_URL}/v1`;
export const POOLS_XVAPE_USDC_URL = '/pools/xvape/usdc';

export const PROFILE_CREATE_URL = '/create-profile';

export const MIGRATE_V1_POOL_URL = `${POOL_URL}/migrate`;
export const PROFILE_PORTFOLIO_URL = '/profile/portfolio';

export const PROFILE_URL = '/profile';

export const REMOVE_POOL_URL = '/remove';

export const ROOT_URL = '/';

export const STAKE_URL = '/stake';

export const STAKE_XVAPE_URL = '/earn/usdc';

export const STRATOSPHERE_PRE_ENROLL_URL = '/stratosphere/enroll';

export const STRATOSPHERE_HOMEPAGE_URL = '/stratosphere/home';

export const PARTNERS_URL = '/stratosphere/partners';

export const SWAP_URL = '/swap';
export const TOKENFACTORY_URL = '/token-factory';

export const REFERRALS_URL = '/referrals';

export const DASHBOARD_URL = '/stratosphere/dashboard';
