/* eslint-disable turbo/no-undeclared-env-vars */
export const BASE_URL =
  typeof window === 'undefined'
    ? process.env.NEXT_PUBLIC_APP_URL
    : window.location.origin;

export const getStratosphereAvatarUrl = (
  tokenId: string,
  address?: `0x${string}`,
) => {
  return `${BASE_URL}/api/stratosphere/${tokenId}/avatar?address=${address}`;
};
