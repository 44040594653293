import { Address } from 'viem';

export type StratosphereTierName =
  | 'Basic'
  | 'Diamond'
  | 'Gold'
  | 'Obsidian'
  | 'Silver'
  | 'Platinum';

// progress -> percentage of points accumulated till yet for this event
export type EventStatus = { isClaimed: boolean; progress?: number };

export interface EventDetails extends Partial<EventStatus> {
  name: string;
  points: number;
  description: string;
}

export type Events = Record<OneTimeEvent, EventDetails>;

export enum StratosphereTiers {
  BASIC = 1,
  SILVER,
  GOLD,
  PLATINUM,
  DIAMOND,
  OBSIDIAN,
}

export type StratosphereAttributes<T> = Record<StratosphereTiers, T>;

export enum OneTimeEvent {
  ENROLLMENT = 0,
  ADD_PROFILE_PICTURE,
  FIRST_SWAP,
  SWAP_1K_CUMULATED,
  SWAP_10K_CUMULATED,
  SWAP_100K_CUMULATED,
  FIRST_DEPOSIT_IN_VPND_LM,
  FIRST_DEPOSIT_IN_VAPE_LM,
  FIRST_WALLET_IN_VPND_LM,
  FIRST_WALLET_IN_VAPE_LM,
  LOCKED_1_SEASON_IN_VPND_LM,
  LOCKED_3_SEASONS_IN_VPND_LM,
  LOCKED_6_SEASONS_IN_VPND_LM,
  LOCKED_1_YEAR_IN_VPND_LM,
  EARLY_MEMBER,
  CHAIN_FIRST_WALLET,
  // REFERRAL_POINTS,
  // SOCIAL_MEDIA_POST_ON_X,
}

export interface StratosphereDashboardProps {
  account: Address;
  claimablePoints: number;
  onClaimPointsClick: () => void;
  tokenId: string;
  claimedPoints: number;
  weeklyPoints: number;
  totalPoints: number;
  availablePoints: number;
  expiringPoints: number;
  progressPercentage: number;
  pendingPoints: number;
  currentTier: StratosphereTiers;
  oneTimeEventStatus: Partial<Record<OneTimeEvent, EventStatus>>;
  currentDate?: Date;
}
