import { Chain, avalanche, avalancheFuji, telos } from 'viem/chains';
import { publicProvider } from 'wagmi/providers/public';
import { mockClient, viemTelosTestnetConfig } from './viem';
import { filterTruthy } from '.';
import { configureChains, createConfig } from 'wagmi';
import { env } from '@/env';
import { ConnectorNames, getConnectors } from '@vaporfi/features';
import { ChainId, ChainKey } from '@vapordex/sdk';

const viemChains = {
  avalanche: {
    ...avalanche,
    contracts: {
      ...avalanche.contracts,
      ensUniversalResolver: {
        address: '0x24DFa1455A75f64800BFdB2447958D2B632b94f6',
      } as const,
    },
  },
  avalancheFuji,
  curtis: {
    blockExplorers: {
      default: {
        name: 'Curtis Explorer',
        url: 'https://curtis.explorer.caldera.xyz',
      },
    },
    contracts: {
      multicall3: {
        address: '0x0852B26500Bf3625a1538FE22E245B9CCd826fbe',
        blockCreated: 7_266_087,
      },
    },
    id: ChainId.CURTIS,
    name: 'Curtis',
    nativeCurrency: {
      decimals: 18,
      name: 'ApeCoin',
      symbol: 'APE',
    },
    network: ChainKey.CURTIS,
    rpcUrls: {
      default: { http: ['https://curtis.rpc.caldera.xyz/http'] },
      public: { http: ['https://curtis.rpc.caldera.xyz/http'] },
    } as const,
    testnet: true,
  } as const,
  telos,
  telosTestnet: viemTelosTestnetConfig,
};
viemTelosTestnetConfig.rpcUrls.default;

const availableChainIDs = env.NEXT_PUBLIC_AVAILABLE_CHAINS?.split(',');
const tokenFactoryChainIDs = env.NEXT_PUBLIC_TOKEN_FACTORY_CHAINS.split(',');

export const getChainsByID = (chainIds: string[]) => {
  if (!chainIds) return [];
  return filterTruthy<Chain>(
    Object.values(viemChains)?.filter(
      ({ id }) => chainIds?.includes(id?.toString()),
    ),
  );
};

export const availableChains = getChainsByID(availableChainIDs);
export const tokenFactoryChains = getChainsByID(tokenFactoryChainIDs);

export const SupportedChainsByFeature = {
  TOKEN_FACTORY: tokenFactoryChains,
};

const { chains, publicClient } = configureChains(availableChains, [
  publicProvider(),
]);
const walletConnectProjectId = 'a8939e88323f259f90b5eeafb696a896';

export const connectorsByName = getConnectors({
  chains,
  mockClient,
  walletConnectProjectId,
});
export const injected = connectorsByName[ConnectorNames.Injected];
export const client = createConfig({
  autoConnect: true,
  connectors: Object.values(connectorsByName),
  publicClient,
});
