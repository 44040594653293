import { ChainId } from '@vapordex/sdk';

const Arbitrum =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/arbitrum.jpg';
const Avalanche =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/avalanche.jpg';
const Bsc =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/bsc.jpg';
const Fantom =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/fantom.jpg';
const Goerli =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/goerli.jpg';
const Harmony =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/harmonyone.jpg';
const Heco =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/heco.jpg';
const Kovan =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/kovan.jpg';
const Mainnet =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/mainnet.jpg';
const Matic =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/polygon.jpg';
const Moonbeam =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/moonbeam.jpg';
const OKEx =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/okex.jpg';
const Polygon =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/polygon.jpg';
const Rinkeby =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/rinkeby.jpg';
const Ropsten =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/ropsten.jpg';
const xDai =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/gnosis.jpg';
const Celo =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/celo.jpg';
const Palm =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/palm.jpg';
const Moonriver =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/moonriver.jpg';
const Fuse =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/fuse.jpg';
const Telos = 'https://static.vapordex.io/tokens/assets/40/TLOS/logo.png';
const Optimism =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/optimism.jpg';
const Kava =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/kava.svg';
const Metis =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/metis.svg';
const ArbitrumNova =
  'https://raw.githubusercontent.com/sushiswap/list/master/logos/network-logos/arbitrum-nova.svg';
const TelosTestnet =
  'https://static.vapordex.io/tokens/assets/40/TLOS/logo.png';
const Curtis =
  'https://static.vapordex.io/8efae81a-b8f1-400a-9c9e-59c4e93a516b.png';
export const NETWORK_ICON: Record<number, string> = {
  [ChainId.ARBITRUM]: Arbitrum,
  [ChainId.ARBITRUM_NOVA]: ArbitrumNova,
  [ChainId.ARBITRUM_TESTNET]: Arbitrum,
  [ChainId.AVALANCHE]: Avalanche,
  [ChainId.AVALANCHE_TESTNET]: Avalanche,
  [ChainId.BSC]: Bsc,
  [ChainId.BSC_TESTNET]: Bsc,
  [ChainId.CELO]: Celo,
  [ChainId.CURTIS]: Curtis,
  [ChainId.ETHEREUM]: Mainnet,
  [ChainId.FANTOM]: Fantom,
  [ChainId.FANTOM_TESTNET]: Fantom,
  [ChainId.FUSE]: Fuse,
  [ChainId.GÖRLI]: Goerli,
  [ChainId.HARMONY]: Harmony,
  [ChainId.HARMONY_TESTNET]: Harmony,
  [ChainId.HECO]: Heco,
  [ChainId.HECO_TESTNET]: Heco,
  [ChainId.KAVA]: Kava,
  [ChainId.KOVAN]: Kovan,
  [ChainId.MATIC]: Polygon,
  [ChainId.MATIC_TESTNET]: Matic,
  [ChainId.METIS]: Metis,
  [ChainId.MOONBEAM]: Moonbeam,
  [ChainId.MOONBEAM_TESTNET]: Moonbeam,
  [ChainId.MOONRIVER]: Moonriver,
  [ChainId.OKEX]: OKEx,
  [ChainId.OKEX_TESTNET]: OKEx,
  [ChainId.OPTIMISM]: Optimism,
  [ChainId.PALM]: Palm,
  [ChainId.RINKEBY]: Rinkeby,
  [ChainId.ROPSTEN]: Ropsten,
  [ChainId.TELOS]: Telos,
  [ChainId.TELOS_TESTNET]: TelosTestnet,
  [ChainId.XDAI]: xDai,
};
