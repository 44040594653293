/* eslint-disable turbo/no-undeclared-env-vars */
import { authentication, createDirectus, rest } from '@directus/sdk';

export interface Partner {
  id: number;
  name: string;
  logo: string;
}

export interface Chain {
  id: number;
  name: string;
}

export interface StratosphereTier {
  id: number;
  name: string;
}
export type Holders = Array<{
  holderName: string;
  holderPercent: string;
  isDev?: boolean;
  isBondingCurve?: boolean;
}>;

export interface BaseToken {
  id: `0x${string}`;
  deployer?: `0x${string}`;
  liquidityPool?: string;
  address: `0x${string}`;
  deployed: boolean;
  name: string;
  marketCap: number;
  holders: Holders;
  holdersBalancePercentage: string[];
  usdValue: number;
  volumeUSD: number;
  buys: number;
  sells: number;
  lastTrade: string;
  logo: string;
  symbol: string;
  chain_id: number;
  decimals: number;
  date_created?: string;
  description?: string;
  website?: string;
  telegram?: string;
  x?: string;
  discord?: string;
}

export interface MoarCandyToken extends BaseToken {
  is_nsfw: boolean;
  devHolding?: boolean;
  progress: number; // bonding curve progress %
  candyKing?: boolean;
  router?: string;
  bondingCurvePercentage?: number;
  devHoldingPercentage?: number;
  currentEthContribution?: number;
  maxSupply?: string;
  circulatingSupply?: string;
  teamWallets?: string[];
  fk_category_id?: string;
}

export interface TokenFactoryOrder {
  id?: any;
  chain_id: number;
  is_stratosphere_whitelisted: boolean;
  is_lp_burned: boolean;
  is_lp_locked: boolean;
  lock_duration_days: number;
  initial_supply: string;
  initial_lp_native: string;
  creator_address: `0x${string}`;
  launch_date: string; // ISO string
  socials_discord_url?: string;
  website?: string;
  socials_telegram_url?: string;
  socials_x_url?: string;
  date_created?: Date;
  date_updated?: Date;
}

export interface FeatureFlag {
  name: number;
  is_enabled: boolean;
  disabled_chains: number[];
}

export interface Offer {
  id?: number;
  fk_partner_id: Partner;
  offer_description: string;
  deadline: string; // ISO string
  promo_code: string;
  offer_link: string;
  fk_chain_id: Chain;
  fk_stratosphere_tiers_id: StratosphereTier;
  is_active: boolean;
  date_created?: Date;
  date_updated?: Date;
}

export interface Chain {
  chain_id: string;
  id: number;
}
export interface Schema {
  tokens: BaseToken[];
  token_factory_orders: TokenFactoryOrder[];
  offers: Offer[];
  feature_flags: FeatureFlag[];
  feature_flags_chains: { id: number; chains_id: number }[];
  chains: Chain[];
}

export interface Thread {
  id: number;
  date_created: Date;
  date_updated: Date;
  message: string;
  is_flagged: boolean | null;
  author: `0x${string}`;
  token: `0x${string}`;
}

export interface User {
  id: string;
  username: string;
  is_active: boolean;
}
export interface UserWatchList {
  id: string;
  fk_user_id: string;
  fk_token_id: string;
}
export interface UserFollowing {
  id: string;
  fk_user_id: string;
  fk_folling_user_id: string;
}
export interface MemeCategory {
  id: string;
  label: string;
  is_active: boolean;
}
export interface MoarCandySchema {
  users: User[];
  tokens: MoarCandyToken[];
  threads: Thread[];
  meme_categories: MemeCategory[];
  user_watch_list: UserWatchList[];
  user_followings: UserFollowing[];
}
export type Collection = keyof Schema;

export const getDirectusClient = <T extends object = Schema>(
  withToken?: boolean,
) =>
  _getDirectusClient<T>(
    process.env.DIRECTUS_URL ?? '',
    withToken ? process.env.DIRECTUS_STATIC_TOKEN : undefined,
  );

export function _getDirectusClient<T extends object = Schema>(
  url: string,
  token?: string,
) {
  if (!url) throw new Error('Missing Params.');

  const client = createDirectus<T>(url).with(authentication()).with(rest());

  if (token) {
    client.setToken(token);
  }
  return client;
}
