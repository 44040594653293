'use client';
import React, { ComponentProps, forwardRef, useRef, useState } from 'react';
import {
  ChevronDownIcon,
  Container,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Option,
  Select,
  Typography,
} from '@vaporfi/uikit';
import { Input } from './components/shared';
import { MemeCategory } from '@vaporfi/db';

const Label = forwardRef<HTMLLabelElement, ComponentProps<typeof FormLabel>>(
  (props, ref) => {
    return (
      <FormLabel className="text-dark font-semibold" {...props} ref={ref} />
    );
  },
);

const CreateForm = ({
  buttons,
  categories,
  form,
  routers,
}: {
  buttons: () => JSX.Element;
  form: any;
  routers: Array<Option>;
  categories: Array<MemeCategory>;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [showMore, setShowMore] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && canvasRef.current) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const img = new Image();
        img.src = reader.result as string;
        img.addEventListener('load', () => {
          const canvas = canvasRef.current;
          const ctx = canvas?.getContext('2d');
          const size = 64;

          if (!canvas || !ctx) return;

          canvas.width = size;
          canvas.height = size;

          const aspectRatio = img.width / img.height;
          let newWidth, newHeight;
          if (aspectRatio > 1) {
            newWidth = size;
            newHeight = size / aspectRatio;
          } else {
            newHeight = size;
            newWidth = size * aspectRatio;
          }

          const x = (size - newWidth) / 2;
          const y = (size - newHeight) / 2;

          ctx.clearRect(0, 0, size, size);
          ctx.drawImage(img, x, y, newWidth, newHeight);

          canvas.toBlob((blob) => {
            if (blob) {
              const resizedFile = new File([blob], file.name, {
                type: file.type,
              });
              form.setValue('logo', resizedFile, { shouldValidate: true });
            }
          });
        });
      });
      reader.readAsDataURL(file);
    }
  };

  const toggleShowMore = () => {
    setShowMore((prevShowMore) => !prevShowMore);
  };
  return (
    <Container stack="column" className="gap-7 rounded-[9px] p-7">
      <Form {...form}>
        <form
          className="flex grid-cols-2 flex-col gap-4 lg:grid"
          onSubmit={form.handleSubmit(() => null)}
        >
          <FormField
            control={form.control}
            name="name"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <Label>Name</Label>
                <FormControl>
                  <Input {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="symbol"
            rules={{ required: true }}
            render={({ field }) => (
              <FormItem>
                <Label>Symbol</Label>
                <FormControl>
                  <Input placeholder="MOAR" {...field} />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem className="col-span-2 min-h-40">
                <Label>Description</Label>
                <FormControl>
                  <Input
                    placeholder="A candy . . . . ."
                    {...field}
                    className="min-h-40"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="logo"
            render={({ field: { value, ...rest } }) => (
              <FormItem>
                <Label>Logo</Label>
                <FormControl>
                  <Input
                    {...rest}
                    type="file"
                    accept="image/*"
                    className="text-dark border-none"
                    onChange={handleFileChange}
                  />
                </FormControl>
                <FormMessage />
                <canvas ref={canvasRef} className="hidden"></canvas>
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="dex"
            render={({ field }) => (
              <FormItem>
                <Label>DEX</Label>
                <FormControl>
                  <Select
                    {...field}
                    options={routers}
                    onSelect={field.onChange}
                    theme="light"
                    icon={
                      <ChevronDownIcon width="30px" className="fill-dark" />
                    }
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="category"
            render={({ field }) => (
              <FormItem>
                <Label>Category</Label>
                <FormControl>
                  <Select
                    {...field}
                    options={categories?.map((cat) => ({
                      label: cat?.label,
                      value: cat?.id,
                    }))}
                    onSelect={field.onChange}
                    theme="light"
                    icon={
                      <ChevronDownIcon width="30px" className="fill-dark" />
                    }
                  />
                </FormControl>

                <FormMessage />
              </FormItem>
            )}
          />

          <div className="col-span-2">
            <button
              type="button"
              onClick={toggleShowMore}
              className="flex items-center"
            >
              <Typography color="purple3">Show more options</Typography>
              <ChevronDownIcon
                className={`fill-purple-3 mt-0.5 transition-transform duration-300 ${
                  showMore ? 'rotate-180' : ''
                }`}
              />
            </button>
          </div>
          {showMore && (
            <>
              <FormField
                control={form.control}
                name="telegram"
                render={({ field }) => (
                  <FormItem>
                    <Label>Telegram URL (Optional)</Label>
                    <FormControl>
                      <Input {...field} placeholder="https://t.me/" />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="x"
                render={({ field }) => (
                  <FormItem>
                    <Label>X/Twitter URL (Optional)</Label>
                    <FormControl>
                      <Input {...field} placeholder="https://x.com/" />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="discord"
                render={({ field }) => (
                  <FormItem>
                    <Label>Discord URL (Optional)</Label>
                    <FormControl>
                      <Input {...field} placeholder="https://discord.gg/" />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="website"
                render={({ field }) => (
                  <FormItem>
                    <Label>Website URL (Optional)</Label>
                    <FormControl>
                      <Input {...field} placeholder="https://moarcandy.com" />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
        </form>
      </Form>
      {buttons?.()}
    </Container>
  );
};

export default CreateForm;
