'use client';
import { Box, Container, Span, Typography } from '@vaporfi/uikit';
import Image from 'next/image';
import { memo } from 'react';
import { StratosphereTiers } from '../types';
import { useCommify } from '@vaporfi/hooks';
import { getStratosphereAvatar, tierToLabel } from '../constants';
import Link from 'next/link';
import { VAPORDEX_DOCS_URL } from '@vaporfi/utils';

const Progress = ({
  currentTier,
  pendingPoints,
  progressPercentage,
}: {
  currentTier: StratosphereTiers;
  pendingPoints: number;
  progressPercentage: number;
}) => {
  const commify = useCommify();
  return (
    <Container className="flex items-center justify-center">
      <Box className="flex w-full flex-col items-center">
        <Typography size="sm" color="light" weight="semibold" className="-mb-4">
          {`${commify(pendingPoints?.toFixed(3))} more points`}
        </Typography>
        <Box className="flex w-full items-center gap-4">
          <Box>
            <Image
              src={
                getStratosphereAvatar(currentTier) ??
                '/images/stratosphere/43114/basic.png'
              }
              height={67}
              width={64}
              alt="Stratosphere tier logo"
              className="rounded-[4px]"
            />
            <Box className="mt-2 flex flex-col gap-1">
              <Typography className="text-xs lg:text-base">
                <Span>Level {currentTier}:</Span>
                <Span font="manverse" className="ml-1">
                  {tierToLabel?.[currentTier]}
                </Span>
              </Typography>
              <Link
                href={VAPORDEX_DOCS_URL}
                target="_blank"
                referrerPolicy="no-referrer"
              >
                <Typography size="sm" color="turquoise">
                  see benefits
                </Typography>
              </Link>
            </Box>
          </Box>

          <ProgressBar progressPercentage={progressPercentage} />

          <Image
            src={
              getStratosphereAvatar(
                currentTier === StratosphereTiers.OBSIDIAN
                  ? currentTier
                  : currentTier + 1,
              ) ?? '/images/stratosphere/43114/basic.png'
            }
            height={67}
            width={64}
            alt="Stratosphere tier logo"
            className="rounded-[4px]"
          />
        </Box>
        <Typography size="sm" color="light" weight="semibold" className="-mt-4">
          {`to reach level ${currentTier + 1}`}
        </Typography>
      </Box>
    </Container>
  );
};

export default memo(Progress);

export const ProgressBar = memo(
  ({ progressPercentage }: { progressPercentage: number }) => {
    return (
      <Box className="h-1 flex-1 rounded-full bg-gray-200">
        <Box
          className="bg-gradient-stratosphere h-full rounded-full"
          style={{ width: `${progressPercentage}%` }}
        ></Box>
      </Box>
    );
  },
);
